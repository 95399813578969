import {globalConfigRegistry} from '@framework/core';
import {fetchAppBaseQuery, RtkQueryUtils} from '@framework/rtk-query';
import {createApi} from '@reduxjs/toolkit/query/react';

const QueryTypes = {
  PastClassSessions: 'ClassSessions/Past',
  FutureClassSessions: 'ClassSessions/Future',
  PastSchedules: 'Schedules/Past',
  FutureSchedules: 'Schedules/Future',
};

function getUrl(relativePath){
  const appConfig = globalConfigRegistry.appConfig;
  return `${appConfig.api}/me/${relativePath}.json`;
}

function getPastClassSessionsQuery(build){
  return build.query({
    query: () => {
      return {
        url: getUrl('sessions/past'),
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result.sessions, QueryTypes.PastClassSessions),
  });
}

function getFutureClassSessionsQuery(build){
  return build.query({
    query: () => {
      return {
        url: getUrl('sessions/future'),
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result.sessions, QueryTypes.FutureClassSessions),
  });
}

function getPastSchedulesQuery(build){
  return build.query({
    query: () => {
      return {
        url: getUrl('schedules/past'),
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result.sessions, QueryTypes.PastSchedules),
  });
}

function getFutureSchedulesQuery(build){
  return build.query({
    query: () => {
      return {
        url: getUrl('schedules/future'),
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result.sessions, QueryTypes.FutureSchedules),
  });
}

export const MeServiceOptions = {
  reducerPath: 'MeService',
  keepUnusedDataFor: 60*15,
  baseQuery: fetchAppBaseQuery('MeService'),
  tagTypes: Object.keys(QueryTypes).map((key) => QueryTypes[key]),
  endpoints: (build) => ({
    getPastClassSessions: getPastClassSessionsQuery(build),
    getFutureClassSessions: getFutureClassSessionsQuery(build),
    getPastSchedules: getPastSchedulesQuery(build),
    getFutureSchedules: getFutureSchedulesQuery(build),
  }),
};

const meService = createApi(MeServiceOptions);

const {
  useGetPastClassSessionsQuery, useGetFutureClassSessionsQuery,
  useGetPastSchedulesQuery, useGetFutureSchedulesQuery,

} = meService;

export const MeService = {
  ...meService,
  QueryTypes: QueryTypes,

  useGetPastClassSessionsQuery, useGetFutureClassSessionsQuery,
  useGetPastSchedulesQuery, useGetFutureSchedulesQuery,
};
