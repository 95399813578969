import {Button} from 'react-bootstrap';
import {ProductsService} from '@app-admin/core';
import {useDispatch} from 'react-redux';
import {standardNotificationActions} from '@framework/react';
import {useState} from 'react';

type SyncProductsButtonProps = {
  children: any,
  className?: string
}

export function SyncProductsButton(props: SyncProductsButtonProps){
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const [syncTrigger] = ProductsService.useRefreshProductsMutation();
  const handleClick = function(){
    setDisabled(true);
    syncTrigger().then(() => dispatch(standardNotificationActions.notifySuccess('products are synced')))
      .then(() => setDisabled(false));
  };

  return <Button {...props} disabled={disabled} onClick={handleClick}>{props.children}</Button>;
}
