import {BookKeepingService} from '@app-admin/core/src/services/book-keeping.service';
import {RtkQueryUtils} from '@framework/rtk-query';
import {ClassSessionsService} from '@app-admin/core';
import {Form, Formik} from 'formik';
import {FormikSelect, FormikSubmitButton, FormikTextField} from '@framework/react-formik';
import {useDispatch} from 'react-redux';
import {
  ConfirmationDialog,
  dismissDialog,
  showDialog,
  standardNotificationActions,
  useDialogActionListener,
} from '@framework/react';
import {formatDate, isBlank, isDefined} from '@framework/core';
import {Button, Card, InputGroup} from 'react-bootstrap';
import {SessionUtils} from '../utils/session-utils';
import './session-action-panel.scss';
import {SessionAssignmentPanel} from './session-assignment-panel';
import {useCallback, useState} from 'react';
import {ClassSessionFormDialog} from './class-session-form-dialog';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {SyncCalendarButton} from './sync-calendar-button';
import {ClassSession, PurchaseOrder} from '@apps/core';
import {LocalTime} from '@apps/core/src/components/local-time';


function AttachToPurchaseOrderForm(props){
  const {session, className} = props;
  const dispatch = useDispatch();

  const poQuery = BookKeepingService.useGetPurchaseOrdersForUserQuery(session.host_user_id);
  const [attachTrigger] = ClassSessionsService.useAddToPurchaseOrderMutation();
  let po: PurchaseOrder[] = [];

  const initialValue = {
    id: session.id,
    po_id: session.po_id,
  };

  if (RtkQueryUtils.isQuerySuccess(poQuery)){
    po = poQuery.data?.data || [];
  }

  const handleAttachToPO = (values, formik) => {
    attachTrigger(values)
      .then((data) => {
        dispatch(standardNotificationActions.notifySuccess('Attached to PO Successfully'));
      })
      .finally(() => formik.setSubmitting(false));
  };

  return <div className={`${className}`}>
    <Formik initialValues={initialValue} onSubmit={handleAttachToPO} enableReinitialize>
      {(formik) => {
        return <Form>
          <div className='form-group'>
            <FormikTextField type='hidden' name='id'/>
          </div>
          <InputGroup>
            <FormikSelect name='po_id' options={po} className='form-control'
              getOptionValue={(opt) => opt.id}
              getOptionLabel={(opt) => `${session.hostUser.name} - ${formatDate(opt.date, 'D MMM')}`}/>
            <FormikSubmitButton name='submit' className='btn btn-primary'>Attach</FormikSubmitButton>
          </InputGroup>
        </Form>;
      }}
    </Formik>
  </div>;
}


function SyncPurchaseOrderAction(props){
  const {session, className} = props;
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const [syncTrigger] = ClassSessionsService.useSyncPurchaseOrderNumberMutation();
  const [removeTrigger] = ClassSessionsService.useRemoveFromPurchaseOrderMutation();
  const [addTrigger] = ClassSessionsService.useAddToPurchaseOrderMutation();

  const dialogId = 'confirm-remove-from-po';


  const handleSync = () => {
    setDisabled(true);
    syncTrigger(session.id)
      .then(() => {
        dispatch(standardNotificationActions.notifySuccess('sync completed'));
      })
      .finally(() => setDisabled(false));
  };

  const handleUpdate = () => {
    setDisabled(true);
    addTrigger({id: session.id, po_id: session.po_id})
      .then(() => {
        dispatch(standardNotificationActions.notifySuccess('update completed'));
      })
      .finally(() => setDisabled(false));
  };

  const handleRemove = function(){
    const config = {
      title: 'Alert',
      message: `Do you want to remove this session from PO @ ${session.po_date}?`,
    };
    dispatch(showDialog(ConfirmationDialog, dialogId, session, config));
  };


  const handleRemoveConfirmed = useCallback((session) => {
    setDisabled(true);

    removeTrigger({id: session.id, po_id: session.po_id})
      .then(() => {
        dispatch(standardNotificationActions.notifySuccess('remove completed'));
      })
      .finally(() => setDisabled(false));
    dispatch(dismissDialog(ConfirmationDialog, dialogId));
  }, [dispatch, dialogId, removeTrigger]);

  useDialogActionListener(ConfirmationDialog, dialogId, ConfirmationDialog.Actions.Ok, handleRemoveConfirmed);

  return <div className={`${className}`}>
    <div className='row'>
      <div className='col-sm-12 col-md-4'>
        <Button onClick={handleUpdate} disabled={disabled} style={{width: '100%'}}><i className='fa fa-upload'/></Button>
      </div>
      <div className='col-sm-12 col-md-4'>
        <Button onClick={handleSync} disabled={disabled} style={{width: '100%'}}><i className='fa fa-download'/> </Button>
      </div>
      <div className='col-sm-12 col-md-4'>
        <Button onClick={handleRemove} disabled={disabled} style={{width: '100%'}} variant='danger'>
          <i className='fa fa-trash'/>
        </Button>
      </div>
    </div>
  </div>;
}

const DlgId = {
  cancelSession: 'cance-session',
};
function SessionButtonBar(props) {
  const {session} = props;
  const dispatch = useDispatch();

  const {t} = useTranslation();

  // const [deleteTrigger] = ClassSessionsService.useDeleteClassSessionMutation();
  const [lockTrigger] = ClassSessionsService.useLockMutation();
  const [unlockTrigger] = ClassSessionsService.useUnlockMutation();
  const [markCompleteTrigger] = ClassSessionsService.useMarkCompletedMutation();
  const [markConfirmedTrigger] = ClassSessionsService.useMarkConfirmedMutation();
  const [markCancelledTrigger] = ClassSessionsService.useMarkCanceledMutation();
  const [markDraftTrigger] = ClassSessionsService.useMarkDraftMutation();

  const handleEdit = function(session){
    dispatch(showDialog(ClassSessionFormDialog, ClassSessionFormDialog.dialogId, session));
  };

  const handleStatusChange = function(session:ClassSession){
    let promise:Promise<any>;
    if (SessionUtils.isDraft(session)){
      promise = markConfirmedTrigger(session.id);
    } else if (SessionUtils.isCompleted(session)){
      promise = markConfirmedTrigger(session.id);
    } else if (SessionUtils.isCancelled(session)) {
      promise = markDraftTrigger(session.id);
    } else if (SessionUtils.isScheduled(session)){
      promise = markCompleteTrigger(session.id);
    }
    promise.then((result) => RtkQueryUtils.processResult(result));
  };

  const handleCancel = function(session: ClassSession){
    const config = {
      title: 'Confirmation',
      message: `Are you sure you want to cancel ${session.hostUser.name}'s session #${session.id} on ${session.date}}`,
    };
    dispatch(showDialog(ConfirmationDialog, DlgId.cancelSession, session, config));
  };

  const handleCancelConfirmed = function(session: ClassSession){
    markCancelledTrigger(session.id).then((result) => RtkQueryUtils.processResult(result))
      .finally(() => {
        dispatch(dismissDialog(ConfirmationDialog, DlgId.cancelSession));
      });
  };
  const handleLockUnlock = function(session: ClassSession){
    let promise:Promise<any>;
    if (SessionUtils.isLocked(session)){
      promise = unlockTrigger(session.id);
    } else {
      promise = lockTrigger(session.id);
    }
    promise.then((result) => RtkQueryUtils.processResult(result));
  };

  const handleSessionSaved = useCallback(() => {
    dispatch(dismissDialog(ClassSessionFormDialog, ClassSessionFormDialog.dialogId));
  }, [dispatch]);

  useDialogActionListener(ClassSessionFormDialog, ClassSessionFormDialog.dialogId,
    ClassSessionFormDialog.Actions.Saved, handleSessionSaved);
  useDialogActionListener(ConfirmationDialog, DlgId.cancelSession, ConfirmationDialog.Actions.Ok, handleCancelConfirmed);

  return <div className='row clearfix session-button-bar'>
    <div className='col-md-6 text-center'>
      <Button variant='primary' onClick={() => handleEdit(session)} disabled={!SessionUtils.canEdit(session)}>
        <i className='fa fa-edit icon-in-btn'/>
        Edit
      </Button>
    </div>
    <div className='col-md-6 text-center'>
      <Button variant='primary' onClick={() => handleLockUnlock(session)} disabled={SessionUtils.isDraft(session)}>
        <i className={`fa fa-${SessionUtils.isLocked(session)? 'lock':'unlock'} icon-in-btn`}/>
        {SessionUtils.isLocked(session)? t('Unlock'): t('Lock')}
      </Button>
    </div>
    <div className='col-md-6 text-center'>
      <Button variant={SessionUtils.isCompleted(session)? 'warning': 'success'}
        onClick={() => handleStatusChange(session)}
        disabled={SessionUtils.isLocked(session)}>
        <i className={`fa fa-clock icon-in-btn`}/>
        {SessionUtils.isCompleted(session)? 'Mark Session Incomplete': ''}
        {SessionUtils.isDraft(session)? 'Mark Session Scheduled': ''}
        {SessionUtils.isCancelled(session)? 'Mark Session Scheduled': ''}
        {SessionUtils.isScheduled(session)? 'Mark Session Complete': ''}
      </Button>
    </div>
    <div className='col-md-6 text-center'>
      <Button variant='danger' onClick={() => handleCancel(session)} disabled={!SessionUtils.canCancel(session)}>
        <i className='fa fa-trash icon-in-btn'/>
        Cancel
      </Button>
    </div>
    <div className='col-md-12'>
      <a target='_blank' rel='noreferrer' href={session.meeting_link} className={`btn btn-primary ${isBlank(session.meeting_link)? 'disabled' : ''}`}>
        <i className={`fa fa-calendar-check icon-in-btn`}/> {t('Join Meeting')}
      </a>
    </div>
  </div>;
}

function AttachToPurchaseOrderAction(props){
  const {session, className} = props;
  const disabled = isDefined(session.po_id);

  return <div className={`${className}`}>
    <h4>Purchase Order</h4>
    {!disabled && <AttachToPurchaseOrderForm session={session}/>}
    {disabled && <SyncPurchaseOrderAction session={session}/>}
  </div>;
}

export function SessionActionPanel(props){
  const {session, onEdit, onDelete, onClose} = props;

  const {t} = useTranslation();

  const date = moment(session.date).format('YYYY-MM-DD');
  const start = moment(session.start).format('HH:mm');

  return <Card className='session-action-panel'>
    <Card.Header className='border-bottom'>
      <h4>
        <SyncCalendarButton session={session}/>
        {session.hostUser.name}: {session.product.name}<i className='fa fal fa-times pull-right close-btn' onClick={onClose}></i>
      </h4>
      <div className='session-subtitle'>
        <span>#{session.id}</span> <span>{session.product.product_key}</span>
        - <span>{SessionUtils.getPODisplayNumber(session)}</span>
      </div>
      <div className='custom-local-time'>
        <ul className='customer-local-time clearfix'>
          <li>
            <span className='country'>UK:</span>
            <LocalTime date={date} time={start} timezone='Europe/London'/>
          </li>
          <li>
            <span className='country'>China:</span>
            <LocalTime date={date} time={start} timezone='Asia/Shanghai'/>
          </li>
          <li>
            <span className='country'>Singapore:</span>
            <LocalTime date={date} time={start} timezone='Asia/Singapore'/>
          </li>
        </ul>
      </div>
    </Card.Header>
    <Card.Body>
      <div className='session-action-section'>
        <AttachToPurchaseOrderAction session={session}/>
      </div>
      <div className='session-action-section border-top'>
        <SessionAssignmentPanel session={session}/>
      </div>
      <div className='session-action-section other-info'>
        <strong>Vendor Invoice:</strong> {session.vendor_invoice_number || '-'}
      </div>
      {session.comments &&
        <div className='session-action-section comments-section'>
          <label>{t('Additional Comments')}</label>
          <div className='comments'>
            {session.comments || '-'}
          </div>
        </div>
      }
    </Card.Body>
    <Card.Footer className='border-top'>
      <SessionButtonBar session={session} onEdit={onEdit} onDelete={onDelete}/>
    </Card.Footer>
  </Card>;
}
