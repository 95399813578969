import {ClientRegistrationForm} from '../components/client-registration-form';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import './registration.page.scss';
import {PageNotFound, RouteWithSubRoutes} from '@framework/react';
import {ClientRegistrationSuccess} from '../components/client-registration-success';
import React from 'react';

const routes = [
  {index: true, component: ClientRegistrationForm},
  {path: 'success', component: ClientRegistrationSuccess},
  {path: '*', component: PageNotFound},
];

export function RegistrationPage(){
  const {t} = useTranslation( );
  return <div className='registration-page'>
    <div className='container registration-form-container'>
      <Card>
        <Card.Header className='border-bottom'>
          <Card.Title className='class-session-list-title'>{t('Student Registration')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <RouteWithSubRoutes routes={routes} />
        </Card.Body>
      </Card>
    </div>
  </div>;
}
