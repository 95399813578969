import './loading-indicator.scss';

type LoadingIndicatorProps = {
  className?: string
}
export function LoadingIndicator(props: LoadingIndicatorProps){
  const {className} = props;
  return <div className={`loading-indicator ${className}`}>
    <i className={`fa fa-spin fa-spinner`}/>
  </div>;
}
