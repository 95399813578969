import * as React from 'react';
import {LanguageSelector} from '@apps/core/src/components/language-selector';
import {selectUserProfile} from '@apps/core';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export function ClientAppNavigationBar(){
  const user = useSelector(selectUserProfile);
  const [t] = useTranslation();
  return (
    <header id='header' className='navbar navbar-expand-lg navbar-end navbar-light navbar-show-hide navbar-untransitioned'>
      <div className='container navbar-topbar'></div>
      <div className='container'>
        <nav className='js-mega-menu navbar-nav-wrap hs-menu-initialized hs-menu-horizontal'>
          <a className='navbar-brand' href=''>
            <img className='navbar-brand-logo' src='/assets/images/logo_purple.png' alt='Logo'/>
            LAMDA Academy
            {process.env.NODE_ENV !== 'production' && ' (Dev)'}
          </a>
          <button className='navbar-toggler' type='button' data-toggle='collapse'
            data-target='#navbarText' aria-controls='navbarText' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNavDropdown'>
            {/*<ul className='navbar-nav'>*/}
            {/*  <li className='nav-item'>*/}
            {/*    <a className='nav-link dropdown-toggle' role='button'>Classes</a>*/}
            {/*  </li>*/}
            {/*</ul>*/}
            {/*<div className='navbar-absolute-top-scroller'>*/}
            {/*/!*<ul className='navbar-nav mr-auto'>*!/*/}
            {/*/!*  <li className='hs-has-sub-menu nav-item'>*!/*/}
            {/*/!*    <a id='accountMegaMenu' className='hs-mega-menu-invoker nav-link dropdown-toggle ' href='app-navigation-bar#'*!/*/}
            {/*/!*      role='button' aria-expanded='false'>Account</a>*!/*/}
            {/*/!*    <div className='hs-sub-menu dropdown-menu hs-sub-menu-desktop-lg animated fadeOut'*!/*/}
            {/*/!*      aria-labelledby='accountMegaMenu'*!/*/}
            {/*/!*    >*!/*/}
            {/*/!*      <div className='hs-has-sub-menu nav-item'>*!/*/}
            {/*/!*        <a id='authenticationMegaMenu' className='hs-mega-menu-invoker dropdown-item dropdown-toggle '*!/*/}
            {/*/!*          href='app-navigation-bar#' role='button' aria-expanded='false'>Authentication</a>*!/*/}

            {/*/!*        <div className='hs-sub-menu dropdown-menu hs-sub-menu-desktop-lg animated'*!/*/}
            {/*/!*          aria-labelledby='authenticationMegaMenu'>*!/*/}
            {/*/!*          <a className='dropdown-item' href='app-navigation-bar#' data-bs-toggle='modal' data-bs-target='#signupModal'>Signup*!/*/}
            {/*/!*            Modal</a>*!/*/}
            {/*/!*          <div className='dropdown-divider'></div>*!/*/}
            {/*/!*          <a className='dropdown-item ' href='app-navigation-bar'>Login</a>*!/*/}
            {/*/!*          <a className='dropdown-item ' href='app-navigation-bar'>Signup</a>*!/*/}
            {/*/!*          <a className='dropdown-item ' href='app-navigation-bar'>Reset Password</a>*!/*/}
            {/*/!*          <div className='dropdown-divider'></div>*!/*/}
            {/*/!*          <a className='dropdown-item ' href='app-navigation-bar'>Login Simple</a>*!/*/}
            {/*/!*          <a className='dropdown-item ' href='app-navigation-bar'>Signup Simple</a>*!/*/}
            {/*/!*          <a className='dropdown-item ' href='app-navigation-bar'>Reset Password Simple</a>*!/*/}
            {/*/!*        </div>*!/*/}
            {/*/!*      </div>*!/*/}

            {/*/!*      <a className='dropdown-item ' href='app-navigation-bar'>Personal Info</a>*!/*/}
            {/*/!*      <a className='dropdown-item ' href='app-navigation-bar'>Security</a>*!/*/}
            {/*/!*      <a className='dropdown-item ' href='app-navigation-bar'>Notifications</a>*!/*/}
            {/*/!*      <a className='dropdown-item ' href='app-navigation-bar'>Preferences</a>*!/*/}
            {/*/!*      <a className='dropdown-item ' href='app-navigation-bar'>Orders</a>*!/*/}
            {/*/!*      <a className='dropdown-item ' href='app-navigation-bar'>Wishlist</a>*!/*/}
            {/*/!*      <a className='dropdown-item ' href='app-navigation-bar'>Payments</a>*!/*/}
            {/*/!*      <a className='dropdown-item ' href='app-navigation-bar'>Address</a>*!/*/}
            {/*/!*      <a className='dropdown-item ' href='app-navigation-bar'>Teams</a>*!/*/}
            {/*/!*    </div>*!/*/}
            {/*  </li>*/}
            {/*  <CurrentUserInfo user={user}/>*/}
            {/*</ul>*/}
            {/*</div>*/}
            <span className='navbar-text'></span>
            <LanguageSelector/>
            {user?.is_admin && <Link to='/admin' role='button' className='btn btn-primary'>{t('Admin')}</Link>}
          </div>
        </nav>
      </div>
    </header>
  );
}
