import {useParams} from 'react-router-dom';
import {ClassSessionsService} from '@app-admin/core';
import {RtkQueryUtils} from '@framework/rtk-query';
import {LoadingIndicator} from '@apps/core/src/components/loading-indicator';
import {SessionActionPanel} from '../components/session-action-panel';
import {useEffect, useState} from 'react';
import {isBlank, isDefined} from '@framework/core';
import {Button, Pagination} from 'react-bootstrap';
import {ClassSessionFormDialog} from '../components/class-session-form-dialog';
import {useDispatch} from 'react-redux';
import {SessionChangeHistoriesPanel} from '../components/session-change-histories-panel';
import {SessionsListBox} from '../components/sessions-list-box';
import {SessionsListTable} from '../components/sessions-list-table';
import './sessions-manager.page.scss';
import {showDialog} from '@framework/react';

ClassSessionFormDialog.dialogId = 'class-session-form-dialog';

export function SessionsManagerPage(props){
  const {timeline = 'future'} = useParams();
  const [sessionId, setSessionId] = useState();
  const [listStyle, setListStyle] = useState('table');
  const [page, setPage] = useState(1);
  const limit = 15;

  const dispatch = useDispatch();

  useEffect(() => {
    setPage(1);
  }, [timeline]);

  const query = ClassSessionsService.useGetTimelineBasedSessionsQuery({timeline, page, limit});
  const sessionQuery = ClassSessionsService.useGetClassSessionQuery(sessionId, {skip: isBlank(sessionId)});

  if (RtkQueryUtils.isLoading(query)){
    return <LoadingIndicator/>;
  }
  const sessions = query.data?.sessions;
  const session = sessionQuery.data?.session;

  const tableWidth = (isDefined(session) ? 8:12);

  const handleSessionSelect = function(session){
    setSessionId(session.id);
  };

  const handleNewSession = function(){
    dispatch(showDialog(ClassSessionFormDialog, ClassSessionFormDialog.dialogId));
  };

  const titleMapping = {future: 'Upcoming', past: 'Past', all: 'All'};
  const title = titleMapping[timeline];
  const showSessionInfo = Boolean(session);

  const handleListViewSwitch = function(style){
    setListStyle(style);
  };

  return <div>
    <h2 style={{marginBottom: '1rem'}}>
      {title} Sessions
      <div className='pull-right' style={{marginRight: '2.3rem'}}>
        <Button variant='primary' size='sm' onClick={handleNewSession}>New Session</Button>
      </div>
    </h2>
    <div className='style-switch clearfix'>
      <i className='fa fa-bars pull-right' onClick={() => handleListViewSwitch('box')}></i>
      <i className='fa fa-list pull-right' onClick={() => handleListViewSwitch('table')}></i>
    </div>
    <div className='row container-fluid'>
      <div className={`col-md-${tableWidth}`} style={{borderRight: '1px solid #dddddd'}}>
        <Pagination className='justify-content-end'>
          <Pagination.Prev disabled={page <= 1} onClick={() => setPage(page -1)}></Pagination.Prev>
          <Pagination.Item>{page}</Pagination.Item>
          <Pagination.Next disabled={sessions.length < limit} onClick={() => setPage(page +1)}></Pagination.Next>
        </Pagination>
        { listStyle === 'box' && <SessionsListBox sessions={sessions} onSelect={handleSessionSelect} />}
        { listStyle === 'table' && <SessionsListTable sessions={sessions} onSelect={handleSessionSelect} />}
        <Pagination className='justify-content-end'>
          <Pagination.Prev disabled={page <= 1} onClick={() => setPage(page -1)}></Pagination.Prev>
          <Pagination.Item>{page}</Pagination.Item>
          <Pagination.Next disabled={sessions.length < limit} onClick={() => setPage(page +1)}></Pagination.Next>
        </Pagination>
      </div>
      <div className={`col-md-${12-tableWidth}`}>
        <div>
          {showSessionInfo && <SessionActionPanel session={session} onClose={() => setSessionId(null)}/>}
          {showSessionInfo && <SessionChangeHistoriesPanel sessionId={session.id}/>}
        </div>
      </div>
    </div>
    <ClassSessionFormDialog id={ClassSessionFormDialog.dialogId}/>
  </div>;
}
