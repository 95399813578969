import React from 'react';
import adminAppRoutes from './admin-app.routes';
import {RouteWithSubRoutes} from '@framework/react';
import {ClientAppNavigationBar} from './components/client-app-navigation-bar';
import './admin.app.scss';
import {useSelector} from 'react-redux';
import {selectUserProfile} from '@apps/core';
import {LoginPage} from './pages/login.page';

export function AdminApp(){
  const user = useSelector(selectUserProfile);

  if (!user || user.is_admin !== 1){
    return <LoginPage/>;
  }

  return (
    <div className='admin-app'>
      <ClientAppNavigationBar/>
      <main className='app content'>
        <div className='container'>
          <RouteWithSubRoutes routes={adminAppRoutes}/>
        </div>
      </main>
    </div>
  );
}

export default AdminApp;
