import {ClassSessionStatus, ClassSessionStatusIndexes} from '@apps/core';
import {formatDate} from '@framework/core';
import {ClassSession} from '@apps/core';

export const SessionUtils = {
  canEdit: (session: ClassSession) => {
    if (SessionUtils.isLocked(session)){
      return false;
    }
    return (session.status === ClassSessionStatus.Draft || session.status === ClassSessionStatus.Scheduled);
  },
  isScheduled: (session: ClassSession) => {
    return (session.status === ClassSessionStatus.Scheduled);
  },
  isCompleted: (session: ClassSession) => {
    return (session.status === ClassSessionStatus.Completed);
  },
  isDraft: (session: ClassSession) => {
    return (session.status === ClassSessionStatus.Draft);
  },
  isCancelled: (session: ClassSession) => {
    return (session.status === ClassSessionStatus.Cancelled);
  },
  isLocked: (session: ClassSession) => {
    return (session.locked === true);
  },
  canDelete: (session: ClassSession) => {
    if (SessionUtils.isLocked(session)){
      return false;
    }
    return (session.status === ClassSessionStatus.Draft);
  },
  canCancel: (session: ClassSession) => {
    if (SessionUtils.isLocked(session)){
      return false;
    }
    return (
      session.status === ClassSessionStatus.Scheduled ||
      session.status === ClassSessionStatus.Draft
    );
  },
  getPODisplayNumber: (item: ClassSession) => {
    if (item.po_number){
      return item.po_number;
    }

    if (item.po_id){
      return `${formatDate(item.po_date, 'D MMM')}`;
    }

    return '-';
  },
  getPOStatusDisplayValue: (item: ClassSession) => {
    if (item.po_number){
      return item.po_status;
    }
    if (item.po_id){
      return 'Pending';
    }

    return '-';
  },
  getStatusDisplayValue: (status: string | number | undefined) => {
    const statusString = (status || '').toString();
    return ClassSessionStatusIndexes[statusString] || 'na';
  },
};
