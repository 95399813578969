import React from 'react';
import {useFormikContext} from 'formik';
import {getFormikFieldError, getFormikFieldValue, isFormikFieldTouched} from './get-formik-field-value';
import {isDefined} from '@framework/core';
import PropTypes from 'prop-types';
import {FormikControlProps} from './formik-control-props';

function defaultGetOptionLabel(option) {
  if (typeof option === 'object') {
    return option['label'] || option;
  }
  return option;
}

function defaultGetOptionValue(option) {
  if (typeof option === 'object') {
    return option['id'] || option;
  }
  return option;
}

// function defaultGetOptionsByGroup(options, grp) {
//   return options.filter((opt) => defaultGetOptionGroupLabel(opt) === defaultGetGroupLabel(grp));
// }

function defaultGetOptionGroupLabel(option){
  return option.group;
}

function defaultGetGroupLabel(grp){
  return grp;
}

// TODO: refactor FormikSelect
type FormikSelectProps = {
  id?: string,
  name?: string,
  options?: any[],
  valueColumnName?: string,
  displayColumnName?: string,
  allowNull?: boolean,
  onChange?: (evt, val) => void,
  getOptionLabel?: (opt) => string | undefined,
  getOptionValue?: (opt) => string | undefined,
  getOptionGroupLabel?: (opt) => string | undefined,
  getGroupLabel?: (opt) => string | undefined,
  groups?: string[]
  // TODO: do we really need the type prop?
  type?: string,
} & FormikControlProps;
export const FormikSelect = (props: FormikSelectProps) => {
  const {name, options=[], placeholder, getOptionLabel, getOptionValue,
    allowNull: propAllowNull, getOptionGroupLabel, getGroupLabel, groups, ...otherProps} = props;

  const allowNull = propAllowNull === undefined || propAllowNull === true;

  const formik = useFormikContext();

  // const _getFormikValue = (option) => {
  //     if (!isDefined(option)) {
  //         return option;
  //     }
  //     return (getFormikValue || defaultGetFormikValue)(option);
  // };
  //
  // const _getFormikOptionValue = (options, value) => {
  //     console.log(value);
  //     if (!isDefined(value)) {
  //         return value;
  //     }
  //
  //     const matches = (multiple ? value : [value]).map((item) => {
  //         return options.find((o) => _getFormikValue(o) === item);
  //     });
  //     return multiple ? matches : matches[0];
  // };

  const _getOptionLabel = (option: any[]) => {
    if (!isDefined(option)) {
      return '';
    }
    return (getOptionLabel || defaultGetOptionLabel)(option) || '';
  };

  const _getOptionValue = (option: any[]) => {
    if (!isDefined(option)) {
      return '';
    }
    return (getOptionValue || defaultGetOptionValue)(option) || '';
  };


  const _getGroupLabel = (grp: string): string => {
    return (getGroupLabel || defaultGetGroupLabel)(grp) || 'Default';
  };

  const _getOptionGroupLabel = (option: any[]): string => {
    return (getOptionGroupLabel || defaultGetOptionGroupLabel)(option) || 'Default';
  };

  const _getOptionsByGroup = (options: any[], grp: string) => {
    return options.filter((opt) => _getOptionGroupLabel(opt) === _getGroupLabel(grp));
  };

  const selectProps:any = {
    name: props.name,
    onBlur: formik.handleBlur,
    ...otherProps,
  };
  selectProps.onChange = (evt) => {
    const value = allowNull === true && evt.target.value === '' ? null : evt.target.value;

    if (props.onChange) {
      props.onChange(evt, value);
    }

    const newEvent = {...evt};
    newEvent.target = {
      name: evt.target.name,
      value: value,
      type: props.type,
    };

    formik.handleChange(newEvent);
  };

  const formikValue = getFormikFieldValue(formik, name);
  const error = isFormikFieldTouched(formik, name) && Boolean(getFormikFieldError(formik, name));
  const helperText = isFormikFieldTouched(formik, name) && getFormikFieldError(formik, name);
  selectProps.value = isDefined(formikValue) ? formikValue : '';

  const getGroupedOptions = () => {
    return groups.map((group, i) => {
      const results: any[] = [];
      const groupOptions = _getOptionsByGroup(options, group);
      const groupLabel = _getGroupLabel(group);
      results.push(
        <optgroup label={groupLabel} key={`opt-group-${groupLabel}`}>
          {groupLabel}
        </optgroup>,
      );
      return results.concat(groupOptions.map((opt, i) => (
        <option key={_getOptionValue(opt)} value={_getOptionValue(opt)}>{_getOptionLabel(opt)}</option>
      )));
    });
  };

  const getOptions = () => {
    return options.map((item) => (
      <option key={_getOptionValue(item)} value={_getOptionValue(item)}>
        {_getOptionLabel(item)}
      </option>
    ));
  };

  return (
    <>
      <select {...selectProps} className={`form-control ${selectProps.className}`}>
        <option value=''>{placeholder}</option>
        {groups && getGroupedOptions()}
        {!groups && getOptions()}
      </select>
      {error && <div className='error'>{helperText}</div>}
    </>
  );
};

FormikSelect.defaultProps = {
  placeholder: '--- SELECT ---',
};
