import {StudentsDashboardPage} from './pages/students-dashboard.page';
import {TeachersDashboardPage} from './pages/teachers-dashboard.page';
import {PageNotFound} from '@framework/react';

const UsersRoutes = [
  {index: true, component: StudentsDashboardPage},
  {path: '/students', component: StudentsDashboardPage},
  {path: '/teachers', component: TeachersDashboardPage},
  {path: '*', component: PageNotFound},
];
export default UsersRoutes;
