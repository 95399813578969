import {ProductsService} from '@app-admin/core';
import {RtkQueryUtils} from '@framework/rtk-query';
import {LoadingIndicator} from '@apps/core/src/components/loading-indicator';
import {Product} from '@apps/core';

type ProductsListTableProps = {
  products: Product[]
}

function ProductsListTable(props: ProductsListTableProps){
  const {products} = props;

  return <div className='student-list-table'>
    <div>
      <table className='table table-hover'>
        <thead>
          <tr>
            <th>Key</th>
            <th>Name</th>
            <th>Active</th>
            <th className='text-center'>Duration</th>
          </tr>
        </thead>
        <tbody>
          {products.map((item) => {
            return <tr key={item.id}>
              <td>{item.product_key}</td>
              <td>{item.name}</td>
              <td>{item.is_active?'Active':'Inactive'}</td>
              <td className='text-center'>{item.duration_min} min</td>
            </tr>;
          })}
        </tbody>
      </table>
    </div>
  </div>;
}


export function ProductsList(){
  const productsQuery = ProductsService.useGetProductsQuery({});

  if (RtkQueryUtils.isLoading(productsQuery)){
    return <LoadingIndicator/>;
  }

  const products = productsQuery.data?.products || [];

  return <div>
    <ProductsListTable products={products}/>
  </div>;
}
