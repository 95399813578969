import {globalConfigRegistry} from '@framework/core';
import {fetchAppBaseQuery, RtkQueryUtils} from '@framework/rtk-query';
import {createApi} from '@reduxjs/toolkit/query/react';
import {EndpointBuilder} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {PurchaseOrder} from '@apps/core/src/models/invoice_ninja/purchase-order';

const QueryTypes = {
  PurchaseOrders: 'PurchaseOrders',
};

function getUrl(relativePath: string){
  const appConfig = globalConfigRegistry.appConfig;
  return `${appConfig.api}/admin/book-keeping/${relativePath}.json`;
}

function getPurchaseOrdersForUserQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{data: PurchaseOrder[]}, {user_id: string}>({
    query: (user_id) => {
      return {
        url: getUrl(`purchase_orders/${user_id}/draft`),
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.listTags(result?.data || [], QueryTypes.PurchaseOrders),
  });
}

export const BookKeepingServiceOptions = {
  reducerPath: 'BookKeepingService',
  keepUnusedDataFor: 60*15,
  baseQuery: fetchAppBaseQuery('BookKeepingService'),
  tagTypes: Object.keys(QueryTypes).map((key) => QueryTypes[key]),
  endpoints: (build:EndpointBuilder<any, string, any>) => ({
    getPurchaseOrdersForUser: getPurchaseOrdersForUserQuery(build),
  }),
};

export const BookKeepingService = createApi(BookKeepingServiceOptions);
