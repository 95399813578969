import React from 'react';
import {RouteWithSubRoutes} from '@framework/react';
import UsersRoutes from './users.routes';

export const UsersModule = (props) => {
  return (
    <div className=''>
      <RouteWithSubRoutes routes={UsersRoutes} />
    </div>
  );
};

UsersModule.propTypes = {
};
