import {useTranslation} from 'react-i18next';
import './client-reset-passcode.scss';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Alert, Button, InputGroup, Modal} from 'react-bootstrap';
import {RtkQueryUtils} from '@framework/rtk-query';
import {dismissDialog, fireDialogAction, showDialog, useDialogStateEvent} from '@framework/react';
import {UserService} from '@apps/core';
import {useDialogRegister} from '@framework/react/src/components/dialogs/hooks/use-dialog-register';

function ResetTokenDialog(){
  const [email, setEmail] = useState('');
  const [processing, setProcessing] = useState(false);
  const [hasReset, setHasReset] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [trigger] = UserService.useResetTokenMutation();
  const {open, data, id} = useDialogStateEvent(ResetTokenDialog);

  useDialogRegister(ResetTokenDialog);

  const handleClose = () => {
    dispatch(
      fireDialogAction(ResetTokenDialog, id, ResetTokenDialog.Actions.Close, data),
    );
    dispatch(dismissDialog(ResetTokenDialog, id));
  };

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handleSendEmail = () => {
    setProcessing(true);
    trigger({em: email})
      .then((result) => RtkQueryUtils.processResult(result))
      .then(() => {
        dispatch(
          fireDialogAction(ResetTokenDialog, id, ResetTokenDialog.Actions.EmailSent, data, {mail: email}),
        );
        setHasReset(true);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return <Modal show={open} onHide={handleClose} reducer-dlg-id={id}
    backdrop='static' keyboard={false} className='client-reset-passcode-dialog'>
    <Modal.Header closeButton>
      <Modal.Title>{t('Reset Passcode')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {hasReset && <Alert variant='success'>{t('reset-passcode-success-msg')}</Alert>}
      <div className='instruction'>{t('reset-passcode-instruction')}</div>
      <InputGroup>
        <input type='text' placeholder={t('Email')} value={email} className='form-control col-md-9' onChange={handleEmailChange}/>
      </InputGroup>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='primary' onClick={handleSendEmail} disabled={processing}>
        {processing && <i className='fa fa-spin fa-spinner'/>}
        {t('Send')}
      </Button>
      <Button variant='secondary' onClick={handleClose} disabled={processing}>{t('Cancel')}</Button>
    </Modal.Footer>
  </Modal>;
}

ResetTokenDialog.DialogTypeName = 'ResetTokenDialog';

ResetTokenDialog.Actions = {
  Close: 'close', EmailSent: 'email-sent',
};

export function ClientResetPasscode(){
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleClick = function(){
    dispatch(showDialog(ResetTokenDialog, 'reset-client-passcode'));
  };

  return <div className='client-reset-passcode'>
    <a href='#' onClick={handleClick}>{t('reset-login-token-link')}</a>
    <ResetTokenDialog/>
  </div>;
}
