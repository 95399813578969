import {useSelector} from 'react-redux';
import {selectUserProfile} from '../reducers';

type CurrentUserTimezoneProps = {
  className?: string,
}

export function CurrentUserTimezone(props: CurrentUserTimezoneProps){
  const user = useSelector(selectUserProfile);
  return <div className={props.className}>Timezone: {user.timezone}</div>;
}
