import {useParams} from 'react-router-dom';
import {ClassSessionsService, UsersService} from '@app-admin/core';
import {RtkQueryUtils} from '@framework/rtk-query';
import {LoadingIndicator} from '@apps/core/src/components/loading-indicator';
import {SessionActionPanel} from '../components/session-action-panel';
import {useState} from 'react';
import {isBlank, isDefined} from '@framework/core';
import {Button, Pagination} from 'react-bootstrap';
import {ClassSessionFormDialog} from '../components/class-session-form-dialog';
import {dismissDialog, showDialog, useDialogActionListener} from '@framework/react';
import {useDispatch} from 'react-redux';
import {SessionChangeHistoriesPanel} from '../components/session-change-histories-panel';
import {SessionsListBox} from '../components/sessions-list-box';
import {SessionsListTable} from '../components/sessions-list-table';
import './user-sessions.page.scss';

export function UserSessionsPage(props){
  const {userId} = useParams();
  const [sessionId, setSessionId] = useState();
  const [listStyle, setListStyle] = useState('table');
  const [page, setPage] = useState(1);
  const limit = 15;

  const dispatch = useDispatch();

  const query = ClassSessionsService.useGetAssignmentsByUserQuery({id: userId, page, limit});
  const userQuery = UsersService.useGetQuery(userId);
  const sessionQuery = ClassSessionsService.useGetClassSessionQuery(sessionId, {skip: isBlank(sessionId)});

  const handleSessionSelect = function(session){
    setSessionId(session.id);
  };

  const handleNewSession = function(){
    dispatch(showDialog(ClassSessionFormDialog, ClassSessionFormDialog.dialogId));
  };

  const handleListViewSwitch = function(style){
    setListStyle(style);
  };

  const handleSessionSaved = (session) => {
    dispatch(dismissDialog(ClassSessionFormDialog, ClassSessionFormDialog.dialogId, session));
  };

  useDialogActionListener(ClassSessionFormDialog, ClassSessionFormDialog.dialogId,
    ClassSessionFormDialog.Actions.Saved, handleSessionSaved, [dispatch]);

  if (RtkQueryUtils.isLoading(query, userQuery)){
    return <LoadingIndicator/>;
  }

  const sessions = query.data?.sessions || [];
  const session = sessionQuery.data?.session;
  const user = userQuery.data.user;

  const tableWidth = (isDefined(session) ? 8:12);

  const showSessionInfo = Boolean(session);

  return <div>
    <h2 style={{marginBottom: '1rem'}}>
      {user.name}&apos;s Sessions
      <div className='pull-right' style={{marginRight: '2.3rem'}}>
        <Button variant='primary' size='sm' onClick={handleNewSession}>New Session</Button>
      </div>
    </h2>
    <div className='style-switch clearfix'>
      <i className='fa fa-bars pull-right' onClick={() => handleListViewSwitch('box')}></i>
      <i className='fa fa-list pull-right' onClick={() => handleListViewSwitch('table')}></i>
    </div>
    <div className='row container-fluid'>
      <div className={`col-md-${tableWidth}`} style={{borderRight: '1px solid #dddddd'}}>
        <Pagination className='justify-content-end'>
          <Pagination.Prev disabled={page <= 1} onClick={() => setPage(page -1)}></Pagination.Prev>
          <Pagination.Item>{page}</Pagination.Item>
          <Pagination.Next disabled={sessions.length < limit} onClick={() => setPage(page +1)}></Pagination.Next>
        </Pagination>
        { listStyle === 'box' && <SessionsListBox sessions={sessions} onSelect={handleSessionSelect} />}
        { listStyle === 'table' && <SessionsListTable sessions={sessions} onSelect={handleSessionSelect} />}
        <Pagination className='justify-content-end'>
          <Pagination.Prev disabled={page <= 1} onClick={() => setPage(page -1)}></Pagination.Prev>
          <Pagination.Item>{page}</Pagination.Item>
          <Pagination.Next disabled={sessions.length < limit} onClick={() => setPage(page +1)}></Pagination.Next>
        </Pagination>
      </div>
      <div className={`col-md-${12-tableWidth}`}>
        <div>
          {showSessionInfo && <SessionActionPanel session={session} onClose={() => setSessionId(null)}/>}
          {showSessionInfo && <SessionChangeHistoriesPanel sessionId={session.id}/>}
        </div>
      </div>
    </div>
    <ClassSessionFormDialog id={ClassSessionFormDialog.dialogId}/>
  </div>;
}
