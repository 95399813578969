import {UserRegistrationsService} from '@app-admin/core';
import {RtkQueryUtils} from '@framework/rtk-query';
import {LoadingIndicator} from '@apps/core/src/components/loading-indicator';
import {Button} from 'react-bootstrap';
import {ConfirmationDialog, dismissDialog, showDialog, useDialogActionListener} from '@framework/react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useCallback} from 'react';
import {User} from '@apps/core';

const userRegistrationStatus = [
  '', 'New', 'Completed', 'Cancelled',
];

const ConfirmDeleteRegistrationDialogId = 'confirm-delete-registration';
const ConfirmCompleteRegistrationDialogId = 'confirm-complete-registration';

type UserRegistrationListTableProps = {
  users: User[]
}

function UserRegistrationListTable(props:UserRegistrationListTableProps){
  const {users} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [cancelTrigger] = UserRegistrationsService.useCancelRegistrationMutation();
  const [completeTrigger] = UserRegistrationsService.useCompleteRegistrationMutation();

  const handleConfirmDelete = (user) => {
    const config = {
      message: 'Are you sure you want to cancel this registration.',
    };
    dispatch(showDialog(ConfirmationDialog, ConfirmDeleteRegistrationDialogId, user, config));
  };

  const handleConfirmComplete = (user) => {
    const config = {
      message: 'Are you sure you have completed student registration?',
    };
    dispatch(showDialog(ConfirmationDialog, ConfirmCompleteRegistrationDialogId, user, config));
  };

  const handleDeleteConfirmed = useCallback((user) => {
    cancelTrigger(user.id)
      .then(() => {
        dispatch(dismissDialog(ConfirmationDialog, ConfirmDeleteRegistrationDialogId));
      });
  }, [dispatch, cancelTrigger]);

  const handleCompleteConfirmed = useCallback((user) => {
    completeTrigger(user.id)
      .then(() => {
        dispatch(dismissDialog(ConfirmationDialog, ConfirmCompleteRegistrationDialogId));
      });
  }, [dispatch, completeTrigger]);

  useDialogActionListener(ConfirmationDialog, ConfirmDeleteRegistrationDialogId,
    ConfirmationDialog.Actions.Ok, handleDeleteConfirmed);
  useDialogActionListener(ConfirmationDialog, ConfirmCompleteRegistrationDialogId,
    ConfirmationDialog.Actions.Ok, handleCompleteConfirmed);


  return <div className='student-list-table'>
    <div>
      <table className='table table-hover'>
        <thead>
          <tr>
            <th>Email</th>
            <th>Student</th>
            <th>Parent</th>
            <th>Status</th>
            <th>Date</th>
            <th className='text-right'>{t('Action')}</th>
          </tr>
        </thead>
        <tbody>
          {users.map((item) => {
            return <tr key={item.id}>
              <td>{item.email}</td>
              <td>{item.first_name} {item.last_name}</td>
              <td>{item.parent_name}</td>
              <td>{userRegistrationStatus[item.status || 0]}</td>
              <td>{moment(item.created_at).format('DD MMM YYYY')}</td>
              <td className='text-right'>
                <Button className='btn btn-sm btn-primary fa fa-check' disabled={item.status !== 1}
                  onClick={() => handleConfirmComplete(item)}></Button>
                <Button className='btn btn-sm btn-danger fa fa-trash' disabled={item.status !== 1}
                  onClick={() => handleConfirmDelete(item)}></Button>
              </td>
            </tr>;
          })}
        </tbody>
      </table>
    </div>
  </div>;
}


export function UserRegistrationList(){
  const query = UserRegistrationsService.useGetAllQuery();

  if (RtkQueryUtils.isLoading(query)){
    return <LoadingIndicator/>;
  }

  const users = query.data?.users || [];

  return <div>
    <UserRegistrationListTable users={users}/>
  </div>;
}
