import {useSelector} from 'react-redux';
import {selectUserProfile} from '@apps/core';
import {ClassSessionList} from '../components/class-session-list';
import {ScheduleList} from '../components/schedule-list';

export function ClassSessionPage(){
  const user = useSelector(selectUserProfile);

  return <div>
    <div>
      {user.is_teacher === 1 && <ClassSessionList/>}
      {user.is_client === 1 && <ScheduleList/>}
    </div>
  </div>;
}
