import React from 'react';
import {RouteWithSubRoutes} from '@framework/react';
import MeRoutes from './me.routes';
import {Card} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {selectUserProfile} from '@apps/core';
import {isDefined} from '@framework/core';
import {useTranslation} from 'react-i18next';

function GuestMessagePlaceHolder(props){
  const {title} = props;
  const {t} = useTranslation();

  return <div className='class-session-table-section'>
    <Card>
      <Card.Header className='border-bottom'>
        <Card.Title className='class-session-list-title'>{title}</Card.Title>
      </Card.Header>
      <Card.Body>
        {t('Please login to access lesson information.')}
      </Card.Body>
    </Card>
  </div>;
}

export const MeModule = (props) => {
  const user = useSelector(selectUserProfile);
  const {t} = useTranslation();

  if (!isDefined(user)){
    return <GuestMessagePlaceHolder title={t('Login Required')}/>;
  }

  return (
    <div className=''>
      <RouteWithSubRoutes routes={MeRoutes} />
    </div>
  );
};

MeModule.propTypes = {
};
