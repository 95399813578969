import {UsersService} from '@app-admin/core';
import {RtkQueryUtils} from '@framework/rtk-query';
import {LoadingIndicator} from '@apps/core/src/components/loading-indicator';
import {SyncUsersButton} from './sync-users-button';
import {Link} from 'react-router-dom';
import {Pagination} from 'react-bootstrap';
import {useState} from 'react';
import {User} from '@apps/core';

type TeacherListTableProps = {
  students: User[]
}

function TeacherListTable(props:TeacherListTableProps){
  const {students} = props;

  return <div className='student-list-table'>
    <div>
      <table className='table table-hover'>
        <thead>
          <tr>
            <th>Client ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Country</th>
            <th>Token</th>
            <th className='text-right'>Total Sessions</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {students.map((item) => {
            return <tr key={item.id}>
              <td>{item.client_id}</td>
              <td><Link to={`/admin/sessions/user/${item.id}`}>{item.name}</Link></td>
              <td>{item.email}</td>
              <td>{item.country}</td>
              <td>{item.token}</td>
              <td className='text-right'>{item.sessions_total}</td>
              <td></td>
            </tr>;
          })}
        </tbody>
      </table>
    </div>
  </div>;
}


export function TeachersList(){
  const [page, setPage] = useState(1);
  const limit = 15;
  const studentsQuery = UsersService.useGetTeachersQuery({page, limit});

  if (RtkQueryUtils.isLoading(studentsQuery)){
    return <LoadingIndicator/>;
  }

  const students = studentsQuery.data?.users || [];

  return <div>
    <h2>Teachers <SyncUsersButton size='sm' className='pull-right'>Sync Users</SyncUsersButton></h2>
    <div>
      <Pagination className='justify-content-end'>
        <Pagination.Prev disabled={page <= 1} onClick={() => setPage(page -1)}></Pagination.Prev>
        <Pagination.Item>{page}</Pagination.Item>
        <Pagination.Next disabled={students.length < limit} onClick={() => setPage(page +1)}></Pagination.Next>
      </Pagination>
      <TeacherListTable students={students}/>
      <Pagination className='justify-content-end'>
        <Pagination.Prev disabled={page <= 1} onClick={() => setPage(page -1)}></Pagination.Prev>
        <Pagination.Item>{page}</Pagination.Item>
        <Pagination.Next disabled={students.length < limit} onClick={() => setPage(page +1)}></Pagination.Next>
      </Pagination>
    </div>
  </div>;
}
