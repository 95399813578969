import {isDefined} from '@framework/core';

export const getFormikFieldValue = (formikContext, fieldName) => {
  return getFormikFieldAttributeValue(formikContext.values, fieldName);
};

export const getFormikFieldError = (formikContext, fieldName) => {
  return getFormikFieldAttributeValue(formikContext.errors, fieldName);
};

export const isFormikFieldTouched = (formikContext, fieldName) => {
  return getFormikFieldAttributeValue(formikContext.touched, fieldName);
};

const getFormikFieldAttributeValue = (formikData, fieldName) => {
  if (fieldName.indexOf('.') === -1) {
    return formikData[fieldName];
  }
  const nestedFields = fieldName.split('.');
  let obj = formikData[nestedFields[0]];

  for (let i = 1; i < nestedFields.length; i++) {
    if (isDefined(obj) && typeof obj === 'object') {
      obj = obj[nestedFields[i]];
    } else {
      obj = undefined;
    }
  }
  return obj;
};
