import {UsersModule} from '@app-admin/users';
import {PageNotFound} from '@framework/react';
import {SessionsModule} from '@app-admin/class-sessions';
import {SystemModule} from '@app-admin/system';

const adminAppRoutes = [
  {path: '/', component: SessionsModule},
  {path: '/users/*', component: UsersModule},
  {path: '/sessions/*', component: SessionsModule},
  {path: '/system/*', component: SystemModule},
  {path: '*', component: PageNotFound},
];

export default adminAppRoutes;
