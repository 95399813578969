import {AdminApp} from '@app-admin/main';
import {ClientApp} from '@app-client/main';
import {PageNotFound} from '@framework/react';

const appRoutes = [
  {path: '/admin/*', component: AdminApp},
  {path: '/*', component: ClientApp},
  {path: '*', component: PageNotFound},
];

export default appRoutes;
