import {ProductsManagerPage} from './pages/products-manager.page';
import {PageNotFound} from '@framework/react';
import {UserRegistrationPage} from './pages/user-registration.page';

const SystemRoutes = [
  {index: true, component: ProductsManagerPage},
  {path: '/products', component: ProductsManagerPage},
  {path: '/registrations', component: UserRegistrationPage},
  {path: '*', component: PageNotFound},
];
export default SystemRoutes;
