import {activityNotificationReducer, DialogStateReducer, standardNotificationReducer} from '@framework/react';
import {configureStore, createListenerMiddleware} from '@reduxjs/toolkit';
import {UserService, UserSessionReducer} from '@apps/core';
import {MeService, UserRegistrationService} from '@app-client/core';
import {ClassSessionsService, ProductsService, UserRegistrationsService, UsersService} from '@app-admin/core';
import {BookKeepingService} from '@app-admin/core';
import {OnlineMeetingsService} from '@app-admin/core/src/services/online-meetings.service';

const services = [
  UserService, MeService,

  // admin module
  UsersService, BookKeepingService, ProductsService,
  ClassSessionsService,
  UserRegistrationsService, UserRegistrationService,
  OnlineMeetingsService,
];

const listenerMiddleware = createListenerMiddleware();

const config = {
  reducer: {
    notification: standardNotificationReducer,
    activityNotification: activityNotificationReducer,
    dialogState: DialogStateReducer,
    session: UserSessionReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      services.map((item) => item.middleware),
    ).prepend(listenerMiddleware.middleware);
  },
};

// registerMockServices();


const duplicatedReducerPaths = services.reduce((result, value, index, data) => {
  const idx = data.findIndex((item) => item.reducerPath === value.reducerPath);
  if ( idx !== index){
    result.push({currentIndex: index, firstIndex: idx, reducerPath: value.reducerPath});
  }
  return result;
}, []);

if (duplicatedReducerPaths.length > 0){
  // eslint-disable-next-line no-console
  console.error('** find duplicated service reducers', duplicatedReducerPaths);
}

services.forEach((item) => config.reducer[item.reducerPath] = item.reducer);

export default configureStore(config);
