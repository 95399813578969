import React from 'react';
import {ErrorMessage, useFormikContext} from 'formik';
import {getFormikFieldValue} from './get-formik-field-value';
import {isDefined} from '@framework/core';
import PropTypes from 'prop-types';
import {FormikControlProps} from './formik-control-props';

type FormikTextAreaProps = {
  id?: string,
  name: string,
  type?: string,
  onChange?: (evt, val) => void,
  maxLength?: number,
  allowNull?: boolean,
  InputProps?: any,
  multiline?: boolean,
  size?: string,
} & FormikControlProps;
export const FormikTextArea = (props:FormikTextAreaProps) => {
  const formik = useFormikContext();

  const {allowNull: propAllowNull, ...otherProps} = props;
  const allowNull = propAllowNull === undefined || propAllowNull === true;

  const textFieldProps: any = {
    onBlur: formik?.handleBlur,
    // put props at the end so we can overwrite
    // other properties from the component props
    ...otherProps,
  };

  textFieldProps.onChange = (evt) => {
    const value = allowNull === true && evt.target.value === '' ? null : evt.target.value;

    const newEvent = {...evt};
    newEvent.target = {
      name: evt.target.name,
      value: value,
      // this is a workaround to formik on change event
      // that on number type it does not return null values
      // so when value is null we force the type to text
      type: value === null ? 'text' : props.type,
    };

    formik.handleChange(newEvent);

    if (props.onChange) {
      props.onChange(evt, value);
    }
  };

  const formikValue = getFormikFieldValue(formik, props.name);
  // const error = isFormikFieldTouched(formik, props.name) && Boolean(getFormikFieldError(formik, props.name));
  // const helperText = isFormikFieldTouched(formik, props.name) && getFormikFieldError(formik, props.name);
  textFieldProps.value = isDefined(formikValue) ? formikValue : '';

  return (
    <>
      <textarea {...textFieldProps} className={`form-control ${textFieldProps.className}`}/>
      <ErrorMessage component='div' name={props.name} className='invalid-feedback'/>
    </>
  );
};

FormikTextArea.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  allowNull: PropTypes.bool,
  className: PropTypes.string,
};
