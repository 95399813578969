import {ClassSessionsService} from '@app-admin/core';
import {useState} from 'react';
import './sync-calendar-button.scss';
import {RtkQueryUtils} from '@framework/rtk-query';
import {useDispatch} from 'react-redux';
import {standardNotificationActions} from '@framework/react';
import {ClassSession} from '@apps/core';

type SyncCalendarButtonProps = {
  session: ClassSession
};

export function SyncCalendarButton(props: SyncCalendarButtonProps){
  const {session} = props;
  const [syncing, setSyncing] = useState(false);
  const dispatch = useDispatch();

  const [syncTrigger] = ClassSessionsService.useSyncCalendarMutation();
  const handleCalendarSync = () => {
    if (syncing){
      return;
    }
    if (!session.id){
      return;
    }
    setSyncing(true);
    syncTrigger(session.id)
      .then((result) => RtkQueryUtils.processResult(result))
      .then(() => dispatch(standardNotificationActions.notifySuccess('calendar sync completed')))
      .finally(() => setSyncing(false));
  };

  const icon=syncing? 'fa-spin fa-spinner': 'fa-calendar-alt';
  return <i className={`sync-calendar-button fa fal ${icon} cal-${session.cal_event_id ? 'on':'off'}`} onClick={handleCalendarSync}/>;
}
