import {useDispatch} from 'react-redux';
import {logoutSuccess} from '@apps/core';

export function LogoutLink(props){
  const dispatch = useDispatch();
  const handleClick = function(){
    dispatch(logoutSuccess());
  };
  return <a {...props} onClick={handleClick}>{props.children}</a>;
}
