import {Modal} from 'react-bootstrap';
import {ClassSessionForm} from './class-session-form';
import {useDispatch} from 'react-redux';
import {dismissDialog, fireDialogAction, useDialogRegister, useDialogStateEvent} from '@framework/react';

export function ClassSessionFormDialog() {
  const dispatch = useDispatch();

  const {open, data, config, id} = useDialogStateEvent(ClassSessionFormDialog);

  useDialogRegister(ClassSessionFormDialog);

  const handleSaved = (session) => {
    dispatch(
      fireDialogAction(ClassSessionFormDialog, id, ClassSessionFormDialog.Actions.Saved, data, session),
    );
  };

  const handleHide = () => {
    dispatch(
      fireDialogAction(ClassSessionFormDialog, id, ClassSessionFormDialog.Actions.Cancel, data, undefined),
    );
    dispatch(dismissDialog(ClassSessionFormDialog, id));
  };

  return (
    <Modal show={open} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>{data?.id ? 'Edit' : 'Create'} Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ClassSessionForm session={data} onSave={handleSaved} isClone={config.isClone}/>
      </Modal.Body>
    </Modal>
  );
}

ClassSessionFormDialog.DialogTypeName = 'ClassSessionFormDialog';
ClassSessionFormDialog.dialogId = 'class-session-form-dlg';

ClassSessionFormDialog.Actions = {
  Saved: 'saved', Cancel: 'cancel',
};
