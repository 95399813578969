import * as React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {selectUserProfile} from '@apps/core';
import {LogoutLink} from './logout-link';


export function ClientAppNavigationBar(){
  const user = useSelector(selectUserProfile);

  return (
    // eslint-disable-next-line max-len
    <header id='header' className='navbar navbar-expand-lg navbar-start navbar-light navbar-show-hide navbar-untransitioned border-bottom'>
      <div className='container navbar-topbar'></div>
      <div className='container'>
        <nav className='js-mega-menu navbar-nav-wrap hs-menu-initialized hs-menu-horizontal'>
          <a className='navbar-brand' href=''>
            <img className='navbar-brand-logo' src='/assets/images/logo_purple.png' alt='Logo'/>
            LAMDA Academy
            {process.env.NODE_ENV !== 'production' && ' (Dev)'}
          </a>
          <button className='navbar-toggler' type='button' data-toggle='collapse'
            data-target='#navbarText' aria-controls='navbarText' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNavDropdown'>
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <Link to='/admin/users/teachers' className='nav-link' role='button'>Teachers</Link>
              </li>
              <li className='nav-item'>
                <Link to='/admin/users/students' className='nav-link' role='button'>Students</Link>
              </li>
              <li className='nav-item dropdown'>
                <a href='#' className='nav-link dropdown-toggle' data-toggle='dropdown'> Sessions</a>
                <div className='dropdown-menu'>
                  <Link to='/admin/sessions/timeline/future' className='dropdown-item'>Upcoming Sessions</Link>
                  <Link to='/admin/sessions/timeline/past' className='dropdown-item'>Past Sessions</Link>
                  <Link to='/admin/sessions/timeline/all' className='dropdown-item'>All Sessions</Link>
                </div>
              </li>
              <li className='nav-item'>
                <a href='https://dolphin-home.DSCloud.me/sharing/4RVn86JwP' className='nav-link' role='button' rel='noreferrer' target='_blank'>Calendar</a>
              </li>
              <li className='nav-item'>
                <a href='https://invoice.leyun.co.uk' className='nav-link' role='button' rel='noreferrer' target='_blank'>Invoice</a>
              </li>
              <li className='nav-item'>
                <Link to='/' className='dropdown-item'>Client Portal</Link>
              </li>
              <li className='nav-item dropdown'>
                <a href='#' className='nav-link dropdown-toggle' data-toggle='dropdown'> System</a>
                <div className='dropdown-menu'>
                  <Link to='/admin/system/registrations' className='dropdown-item'>Registrations</Link>
                  <Link to='/admin/system/products' className='dropdown-item'>Products</Link>
                  <a href='https://app.i18nexus.com/projects/mVj6Hvujb9TtFgazfVJX6V/translations' className='dropdown-item' rel='noreferrer' target='_blank'>i18n Tool</a>
                </div>
              </li>
            </ul>
            <span className='navbar-text'></span>
            <div className='current-user-name'>
              {user.name}
              <LogoutLink className='fa fa-sign-out-alt'></LogoutLink>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
