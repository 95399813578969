import {UserTokenForm} from './user-token-form';
import {ChangeUserToken} from './change-user-token';
import React from 'react';
import {useSelector} from 'react-redux';
import {selectUserProfile} from '@apps/core';
import './app-body-nav.scss';
import {useTranslation} from 'react-i18next';

export function AppBodyNav(){
  const user = useSelector(selectUserProfile);
  const {t} = useTranslation();

  return <div className='app-body-nav'>
    <div className='navbar-dark bg-purple'>
      <div className='container content-space-1 content-space-b-lg-1'>
        <div className='row'>
          <div className='col-md-4 col-sm-12 page-title'>
            {t('Booking System')}
          </div>
          <div className='col-md-8 col-sm-12'>
            {!user && <UserTokenForm/>}
            {user && <ChangeUserToken user={user}/>}
          </div>
        </div>
      </div>
    </div>
  </div>;
}
