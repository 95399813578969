import moment from 'moment-timezone';
import {isDefined} from '@framework/core';

export function getCustomerLocalTime(date: string, time: string, timezone?: string) {
  let dt = moment();
  if (isDefined(date) && isDefined(time)) {
    dt = moment(`${date}T${time}:00`);
  }
  if (!timezone){
    timezone = moment.tz.guess();
  }
  // const gmtTime = dt.add(new Date().getTimezoneOffset(), "minutes");
  // console.log(gmtTime, dt);
  return dt.tz(timezone);
}
