import {SessionsManagerPage} from './pages/sessions-manager.page';
import {PageNotFound} from '@framework/react';
import {UserSessionsPage} from './pages/user-sessions.page';

const SessionsRoutes = [
  {index: true, component: SessionsManagerPage},
  {
    path: 'timeline/:timeline/*', component: SessionsManagerPage,
  },
  {
    path: 'user/:userId', component: UserSessionsPage,
  },
  {path: '*', component: PageNotFound},
];
export default SessionsRoutes;
