import {MeService} from '@app-client/core';
import PropTypes from 'prop-types';
import {ClassSessionStatus, CurrentUserTimezone, getCustomerLocalTime, selectUserProfile} from '@apps/core';
import {RtkQueryUtils} from '@framework/rtk-query';
import {LoadingIndicator} from '@apps/core/src/components/loading-indicator';
import moment from 'moment';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {LocalTime} from '@apps/core/src/components/local-time';

function EmptySessions(props){
  const {timing} = props;
  const {t} = useTranslation();
  const {title} = props;
  return <div className='class-session-table-section'>
    <Card>
      <Card.Header className='border-bottom'>
        <Card.Title className='class-session-list-title'>{title}</Card.Title>
      </Card.Header>
      <Card.Body>
        {t(`teacher-no-sessions-booked-${timing}`)}
      </Card.Body>
    </Card>
  </div>;
}

EmptySessions.propTypes = {
  title: PropTypes.string,
};

function ScheduleTableSection(props){
  const {title, sessions, timing} = props;
  const {t} = useTranslation();
  const user = useSelector(selectUserProfile);

  if (sessions.length === 0){
    return <EmptySessions title={title} timing={timing}/>;
  }

  return <div className='class-session-table-section'>
    <Card>
      <Card.Header className='border-bottom'>
        <Card.Title className='class-session-list-title'>{title}</Card.Title>
      </Card.Header>
      <Card.Body>
        <div>
          <div className='clearfix'>
            <CurrentUserTimezone className='pull-right'/>
          </div>
          <table className='table class-session-table'>
            <thead>
              <tr>
                <th>{t('Date')}</th>
                <th>{t('Teacher')}</th>
                <th className='text-center'>{t('UK Time')}</th>
                <th className='text-center'>{t('Local Time')}</th>
                <th>{t('Duration')}</th>
                <th>{t('Session Name')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sessions.map((item) => {
                const date = moment(item.date).format('YYYY-MM-DD');
                const start = moment(item.start).format('HH:mm');
                const enableJoinBtn = item.class_session.meeting_link &&
                  item.class_session.status === ClassSessionStatus.Scheduled;
                return <tr key={item.id}>
                  <td>{moment(item.class_session.date).format('D MMM YYYY')}</td>
                  <td>{item.class_session.hostUser.name}</td>
                  <td className='text-center'><LocalTime date={date} time={start} timezone={'Europe/London'}/></td>
                  <td className='text-center'><LocalTime date={date} time={start} timezone={user.timezone}/></td>
                  <td>{item.class_session.duration} {t('minute')}</td>
                  <td>{item.class_session.product.name}</td>
                  <td className='join-session'><a href={item.class_session.meeting_link} target='_blank' className={`btn btn-sm btn-primary btn-join ${enableJoinBtn? '': 'disabled'}`} rel='noreferrer'>{t('Join Meeting')}</a></td>
                </tr>;
              })}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  </div>;
}

ScheduleTableSection.propTypes = {
  title: PropTypes.string,
  sessions: PropTypes.array,
};

export function ScheduleList(props){
  const {t} = useTranslation();

  const pastSessionQuery = MeService.useGetPastSchedulesQuery();
  const futureSessionQuery = MeService.useGetFutureSchedulesQuery();

  if (RtkQueryUtils.isLoading(pastSessionQuery, futureSessionQuery)){
    return <LoadingIndicator/>;
  }

  return <div className='class-session-list'>
    <ScheduleTableSection title={t('Upcoming Sessions')} sessions={futureSessionQuery.data.sessions} timing='future'/>
    <ScheduleTableSection title={t('Past Sessions')} sessions={pastSessionQuery.data.sessions} timing='past'/>
  </div>;
}
