// eslint-disable-next-line react-bespoke/enforce-storybook
import {useGlobalAppConfigRegistry} from '@framework/react';
import {App} from '@apps/main';
import 'react-datepicker/dist/react-datepicker.css';
import {Suspense} from 'react';

export function AppEntry(){
  const appConfigReg = useGlobalAppConfigRegistry();

  // if (process.env.NODE_ENV === 'production'){
  //   appConfigReg.appConfig = require('./configs/config.prod').default;
  // } else {
  appConfigReg.appConfig = require('./configs/config').default;
  // }

  return <Suspense fallback={null}>
    <App/>
  </Suspense>;
}
