import {useDispatch} from 'react-redux';
import {loginSuccess, UserService} from '@apps/core';
import {FormikSubmitButton, FormikTextField} from '@framework/react-formik';
import {Form, Formik} from 'formik';
import {useState} from 'react';
import {Alert, Card} from 'react-bootstrap';
import './login-form.scss';
import {RtkQueryUtils} from '@framework/rtk-query';


const initialValue = {
  email: '', password: '',
};
export function LoginForm(){
  const dispatch = useDispatch();
  const [loginTrigger] = UserService.useLoginMutation();
  const [authFailed, setAuthFailed] = useState<boolean>();
  const handleSubmit = function(values, formik){
    loginTrigger(values)
      .then(RtkQueryUtils.processResult)
      .then((result) => {
        dispatch(loginSuccess({user: result.user, token: result.token}));
      })
      .catch(() => setAuthFailed(true))
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  return <Card>
    <Card.Header className='border-bottom'>
      <Card.Title className='class-session-list-title'>Login</Card.Title>
    </Card.Header>
    <Card.Body>
      <Formik initialValues={initialValue} onSubmit={handleSubmit}>
        {(formik) => {
          return <Form>
            {authFailed && <Alert variant='danger'>Your account is not found, please try again</Alert>}
            <div className='form'>
              <div className='form-group'>
                <label className='form-label'>Email</label>
                <FormikTextField name='email' placeholder='Email'/>
              </div>
              <div className='form-group'>
                <label className='form-label'>Password</label>
                <FormikTextField type='password' name='password' placeholder='Password'/>
              </div>
              <div className='form-group'>
                <label className='form-label'>&nbsp;</label>
                <FormikSubmitButton name='submit' className='btn btn-primary'>Login</FormikSubmitButton>
              </div>
            </div>
          </Form>;
        }}
      </Formik>
    </Card.Body>
  </Card>;
}
