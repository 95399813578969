import {globalConfigRegistry} from '@framework/core';
import {fetchAppBaseQuery, RtkQueryUtils} from '@framework/rtk-query';
import {createApi} from '@reduxjs/toolkit/query/react';

const QueryTypes = {
  UserRegistration: 'UserRegistration',
};

function getUrl(relativePath){
  const appConfig = globalConfigRegistry.appConfig;
  return `${appConfig.api}/register${relativePath}.json`;
}

function getQuery(build){
  return build.query({
    query: () => {
      return {
        url: getUrl(''),
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.listTags(result.users, QueryTypes.ClassSessions),
  });
}

function registerMutation(build){
  return build.mutation({
    query: (data) => {
      return {
        url: getUrl('/students'),
        method: 'POST',
        body: data,
      };
    },
    // transformResponse: (response) => response.data,
    providesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result, QueryTypes.UserRegistration),
  });
}

function markProcessedMutation(build){
  return build.mutation({
    query: (id) => ({
      url: getUrl('/mark-processed'),
      method: 'POST',
      body: {id: id},
    }),
    providesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result, QueryTypes.UserRegistration),
    // invalidatesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result, QueryTypes.UserRegistration),
  });
}

function deleteMutation(build){
  return build.mutation({
    query: (id) => ({
      url: getUrl(`/${id}/delete`),
      method: 'POST',
    }),
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([result.session], QueryTypes.UserRegistration),
  });
}

export const UserRegistrationServiceOptions = {
  reducerPath: 'UserRegistrationService',
  keepUnusedDataFor: 0,
  baseQuery: fetchAppBaseQuery('UserRegistrationService'),
  tagTypes: Object.keys(QueryTypes).map((key) => QueryTypes[key]),
  endpoints: (build) => ({
    get: getQuery(build),
    register: registerMutation(build),
    markProcessed: markProcessedMutation(build),
    delete: deleteMutation(build),
  }),
};

const service = createApi(UserRegistrationServiceOptions);

const {
  useGetQuery, useRegisterMutation, useMarkProcessedMutation,
  useDeleteMutation,
} = service;

export const UserRegistrationService = {
  ...service,
  QueryTypes: QueryTypes,

  useGetQuery, useRegisterMutation, useMarkProcessedMutation,
  useDeleteMutation,
};
