// eslint-disable-next-line react-bespoke/js-filename-standard
import React from 'react';
import clientAppRoutes from './client-app.routes';
import {RouteWithSubRoutes} from '@framework/react';
import {ClientAppNavigationBar} from './components/client-app-navigation-bar';
import './client-app.scss';
import {AppBodyNav} from './components/app-body-nav';

export function ClientApp(){
  return (
    <div className='client-app'>
      <ClientAppNavigationBar/>
      <main className='app content'>
        <AppBodyNav/>
        <div className='container' style={{marginTop: '-2rem'}}>
          <RouteWithSubRoutes routes={clientAppRoutes}/>
        </div>
      </main>
    </div>
  );
}

export default ClientApp;
