import './edit-icon-button.scss';

type DuplicateIconButtonProps = {
  data: any,
  onDuplicate: (data: any) => void,
  className?: string,
  disabled?: boolean
}

export function DuplicateIconButton(props: DuplicateIconButtonProps){
  const {data, onDuplicate, className, disabled = false, ...otherProps} = props;
  const icon = 'fa-copy';
  const handleDuplicate = function(){
    if (onDuplicate){
      onDuplicate(data);
    }
  };
  return <i {...otherProps} className={`fa fal edit-icon-button ${icon} ${className} ${disabled? 'disabled':''}`} onClick={handleDuplicate}></i>;
}
