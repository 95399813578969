import {useDispatch} from 'react-redux';
import {loginSuccess, UserService} from '@apps/core';
import {FormikSubmitButton, FormikTextField} from '@framework/react-formik';
import {Form, Formik} from 'formik';
import {useState} from 'react';
import {Alert} from 'react-bootstrap';
import './user-token-form.scss';
import {useTranslation} from 'react-i18next';
import {ClientResetPasscode} from './client-reset-passcode';


const initialValue = {
  fn: '', ln: '', tk: '',
};
export function UserTokenForm(){
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [verifyTokenTrigger] = UserService.useTokenMutation();
  const [authFailed, setAuthFailed] = useState();
  const handleSubmit = function(values, formik){
    verifyTokenTrigger(values)
      .then((result) => {
        const data = result.data;
        dispatch(loginSuccess({user: data.user, token: data.token}));
      })
      .catch(() => setAuthFailed(true))
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  return <Formik initialValues={initialValue} onSubmit={handleSubmit}>
    {(formik) => {
      return <Form className='user-token-form'>
        {authFailed && <Alert variant='danger'>{t('Your account is not found, please try again')}</Alert>}
        <div className='row gx-2 gx-md-3 mb-7'>
          <div className='col-md-4 mb-2 mb-md-0'>
            <label className='form-label'>{t('Email')}</label>
            <FormikTextField name='em' placeholder={t('Email')}/>
          </div>
          <div className='col-md-3 mb-2 mb-md-0'>
            <label className='form-label'>{t('First Name')} ({t('Student')})</label>
            <FormikTextField name='fn' placeholder={t('First Name')}/>
          </div>
          {/*<div className='col-md-3 mb-2 mb-md-0'>*/}
          {/*  <label className='form-label'>Last Name</label>*/}
          {/*  <FormikTextField name='ln' placeholder='Last Name'/>*/}
          {/*</div>*/}
          <div className='col-md-3 mb-2 mb-md-0'>
            <label className='form-label'>{t('User Token')}</label>
            <FormikTextField type='password' name='tk' placeholder={t('User Token')}/>
          </div>
          <div className='col-md-2 mb-2 mb-md-0'>
            <label className='form-label'>&nbsp;</label>
            <FormikSubmitButton className='btn btn-primary form-control'>{t('Login')}</FormikSubmitButton>
          </div>
          <div className='text-left'>
            <ClientResetPasscode/>
          </div>
        </div>
      </Form>;
    }}
  </Formik>;
}
