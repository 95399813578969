import {MeModule} from '@app-client/me';
import {RegisterModule} from '@app-client/register';

const clientAppRoutes = [
  {path: '', component: MeModule},
  {path: '/register/*', component: RegisterModule},
  {path: '/me/*', component: MeModule},
  {path: '*', component: MeModule},
];

export default clientAppRoutes;
