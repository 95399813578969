import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

export const LanguageSelector = () => {
  const {t, i18n} = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };


  // const handleChange = (e) => {
  //   setLanguage(e.target.value);
  //   let loc = "http://localhost:3000/";
  //   window.location.replace(loc + "?lng=" + e.target.value);
  // }

  const options = [
    {value: 'en', name: 'English'},
    {value: 'zh-cn', name: 'Chinese'},
  ];
  return (
    <select value={language} onChange={handleChangeLanguage} className='form-control'>
      {options.map((lan, idx) => {
        return <option key={idx} value={lan.value}>{t(lan.name)}</option>;
      })}
    </select>
  );
};
