import {Alert} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

export function ClientRegistrationSuccess(props){
  const {t} = useTranslation();

  return <div>
    <Alert variant='success'>
      <Alert.Heading>{t('Registration Success')}</Alert.Heading>
      {t('student-registration-success')}
    </Alert>
  </div>;
}
