import React from 'react';
import {RouteWithSubRoutes} from '@framework/react';
import RegisterRoutes from './register.routes';

export const RegisterModule = (props) => {
  return (
    <div className=''>
      <RouteWithSubRoutes routes={RegisterRoutes} />
    </div>
  );
};

RegisterModule.propTypes = {
};
