import React from 'react';
import {LoginForm} from '../components/login-form';
import './login.page.scss';

export function LoginPage(){
  return <div className='client-app'>
    <main className='app content login-page'>
      <div className='container login-window'>
        <LoginForm/>
      </div>
    </main>
  </div>;
}
