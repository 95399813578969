import PropTypes from 'prop-types';
import './edit-icon-button.scss';

type EditIconButtonProps = {
  className?: string,
  disabled?: boolean,
  data: any,
  onEdit:(data) => void,
}

export function EditIconButton(props: EditIconButtonProps){
  const {data, onEdit, className, ...otherProps} = props;
  const icon = 'fa-edit';
  const handleEdit = function(){
    if (onEdit){
      onEdit(data);
    }
  };
  return <i {...otherProps} className={`fa fal edit-icon-button ${icon} ${className} ${props.disabled? 'disabled':''}`} onClick={handleEdit}></i>;
}

EditIconButton.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  className: PropTypes.string,
};
