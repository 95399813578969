import {FormikSelect, FormikSubmitButton, FormikTextField} from '@framework/react-formik';
import {Form, Formik} from 'formik';
import {useState} from 'react';
import {Alert} from 'react-bootstrap';
import '@app-client/main/src/components/user-token-form.scss';
import * as yup from 'yup';
import {UserRegistrationService} from '@app-client/core/src/services/user-registration.service';
import {useTranslation} from 'react-i18next';
import {isDefined} from '@framework/core';
import {useNavigate} from 'react-router-dom';
import {RtkQueryUtils} from '@framework/rtk-query';

const ClientRegistrationFormSchema = yup.object().shape({
  email: yup.string().email().required().nullable(true),
  first_name: yup.string().required().nullable(true),
  last_name: yup.string().required().nullable(true),
  parent_name: yup.string().optional().nullable(true),
  country: yup.string().required().nullable(true),
});


const countries = [
  {name: 'China', currency: 'CNY'},
  {name: 'Hong Kong', currency: 'HKD'},
  {name: 'Singapore', currency: 'SGD'},
  {name: 'United Kingdom', currency: 'GBP'},
];

const initialValue = {
  email: '', first_name: '', last_name: '', parent_name: '', country: '',
};

export function ClientRegistrationFailed(props){
  const {msg} = props;
  const {t} = useTranslation();

  return <div>
    <Alert variant='danger'>
      <Alert.Heading>{t('Registration Error')}</Alert.Heading>
      {t(msg)}
    </Alert>
  </div>;
}

function StudentNameFormSection(){
  const {t, i18n} = useTranslation();
  const language = i18n.language;

  if (language === 'zh-cn'){
    return <div className='row clearfix'>
      <div className='form-group col-md-6'>
        <label className='form-label'>{t('Last Name')} ({t('Student')})</label>
        <FormikTextField name='last_name' placeholder={t('Last Name')}/>
      </div>
      <div className='form-group col-md-6'>
        <label className='form-label'>{t('First Name')} ({t('Student')})</label>
        <FormikTextField name='first_name' placeholder={t('First Name')}/>
      </div>
    </div>;
  }
  return <div className='row clearfix'>
    <div className='form-group col-md-6'>
      <label className='form-label'>{t('First Name')} ({t('Student')})</label>
      <FormikTextField name='first_name' placeholder={t('First Name')}/>
    </div>
    <div className='form-group col-md-6'>
      <label className='form-label'>{t('Last Name')} ({t('Student')})</label>
      <FormikTextField name='last_name' placeholder={t('Last Name')}/>
    </div>
  </div>;
}

export function ClientRegistrationForm(){
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [trigger] = UserRegistrationService.useRegisterMutation();
  const [registrationFailed, setRegistrationFailed] = useState();

  const handleSubmit = function(values, formik){
    trigger(values)
      .then((result) => RtkQueryUtils.processResult(result))
      .then((data) => {
        formik.resetForm();
        navigate('success');
      })
      .catch((err) => {
        setRegistrationFailed(RtkQueryUtils.getErrorMessage(err));
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  return <Formik initialValues={initialValue} onSubmit={handleSubmit} validationSchema={ClientRegistrationFormSchema}>
    {(formik) => {
      return <Form className='user-registration-form'>
        {isDefined(registrationFailed) && <ClientRegistrationFailed msg={registrationFailed}/>}
        <div className='form'>
          <div className='form-group'>
            <label className='form-label'>{t('Email')}</label>
            <FormikTextField name='email' placeholder={t('Email')}/>
          </div>
          <div className='form-group'>
            <label className='form-label'>{t('Parent Name')} ({t('Optional')})</label>
            <FormikTextField name='parent_name' placeholder={t('Parent Name')}/>
          </div>
          <StudentNameFormSection/>
          <div className='form-group'>
            <label className='form-label'>{t('Country')}</label>
            <FormikSelect options={countries} name='country'
              getOptionValue={(opt) => opt.name}
              getOptionLabel={(opt) => t(opt.name) } />
          </div>
          <div className='form-group'>
            <label className='form-label'>&nbsp;</label>
            <FormikSubmitButton className='btn btn-primary'>{t('Submit')}</FormikSubmitButton>
          </div>
        </div>
      </Form>;
    }}
  </Formik>;
}
