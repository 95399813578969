import {ClassSessionsService, UsersService} from '@app-admin/core';
import {RtkQueryUtils} from '@framework/rtk-query';
import {LoadingIndicator} from '@apps/core/src/components/loading-indicator';
import {Alert, Badge, InputGroup} from 'react-bootstrap';
import {Form, Formik} from 'formik';
import {FormikSelect, FormikSubmitButton, FormikTextField} from '@framework/react-formik';
import './session-assignment-panel.scss';
import {useState} from 'react';
import {standardNotificationActions} from '@framework/react';
import {useDispatch} from 'react-redux';
import {SessionUtils} from '../utils/session-utils';
import {ClassAssignment, ClassSession, User} from '@apps/core';

type SessionAssignmentFormProps = {
  assignments: ClassAssignment[],
  sessionId: number,
  disabled?: boolean,
}

function SessionAssignmentForm(props:SessionAssignmentFormProps){
  const {assignments: currentAssignments, sessionId, disabled = false} = props;
  const [selectedUser, setSelectedUser] = useState<User>();

  const dispatch = useDispatch();
  const studentsQuery = UsersService.useGetStudentsQuery({page: 1, limit: 100, is_active: 1});
  const [addTrigger] = ClassSessionsService.useAddAssignmentMutation();

  const students = studentsQuery.data?.users || [];
  const initialValues = {
    user_id: null, class_session_id: sessionId,
  };

  const unassignedStudents: User[] = [];

  students.forEach((item) => {
    if (item && !currentAssignments.find((ass) => ass.user?.id === item.id)){
      unassignedStudents.push(item);
    }
  });

  const handleSubmit = (values, formik) => {
    addTrigger(values)
      .then((data) => {
        studentsQuery.refetch();
        setSelectedUser(undefined);
        dispatch(standardNotificationActions.notifySuccess('Success'));
      })
      .finally(() => formik.setSubmitting(false));
  };

  const handleUserChange = (val, userId) => {
    const user = unassignedStudents.find((u) => u.id === parseInt(userId));
    setSelectedUser(user);
  };

  return <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
    {(formik) => {
      return <Form className='form-group'>
        <FormikTextField type='hidden' name='class_session_id'/>
        <InputGroup>
          <FormikSelect options={unassignedStudents} className='form-control' name='user_id'
            type='number'
            disabled={disabled}
            onChange={handleUserChange}
            groups={['Top Selection', 'Out of Balance']}
            getOptionGroupLabel={(opt) => opt.sessions_balance > 0? 'Top Selection':'Out of Balance'}
            getOptionValue={(opt) => opt.id}
            getOptionLabel={(opt) => `${opt.name} (${opt.sessions_balance} sessions remain)`}/>
          <FormikSubmitButton name='submit' className='btn btn-primary' disabled={disabled}>Add</FormikSubmitButton>
        </InputGroup>
        {selectedUser && (selectedUser.sessions_balance || 0) <=0 &&
          <Alert variant='warning' className='balance-warning'>
            <strong>Warning:</strong> {selectedUser.name} may not have enough sessions available
          </Alert>
        }
      </Form>;
    }}
  </Formik>;
}

type SessionAssignmentPanelProps = {
  session: ClassSession
}

export function SessionAssignmentPanel(props:SessionAssignmentPanelProps){
  const {session} = props;

  const assignmentsQuery = ClassSessionsService.useGetAssignmentsQuery({session_id: session.id || 0});
  const [removeTrigger] = ClassSessionsService.useRemoveAssignmentMutation();

  if (RtkQueryUtils.isLoading(assignmentsQuery)){
    return <LoadingIndicator/>;
  }

  const assignments = assignmentsQuery.data?.sessions || [];

  const handleRemoveAssignment = (item) => {
    return removeTrigger({session_id: item.class_session_id, id: item.id});
  };

  return <div className='session-assignment-panel'>
    <h4 style={{marginTop: '1rem'}}>Students</h4>
    {!SessionUtils.isLocked(session) &&
      <SessionAssignmentForm sessionId={session.id || 0} assignments={assignments}
        disabled={SessionUtils.isLocked(session)}/>}
    <div className='assignment-badges'>
      {assignments.map((item) => {
        return <Badge key={item.id} className='badge-assigned-user'>
          {item.user?.name}
          {!SessionUtils.isLocked(session) &&
            <i className='fa fa-times' onClick={() => handleRemoveAssignment(item)}/>
          }
        </Badge>;
      })}
      {assignments.length === 0 && <span>Students are not allocated</span>}
    </div>
  </div>;
}
