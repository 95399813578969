import React from 'react';
import {RouteWithSubRoutes} from '@framework/react';
import SessionsRoutes from './sessions.routes';

export const SessionsModule = () => {
  return (
    <div className='sessions-module'>
      <RouteWithSubRoutes routes={SessionsRoutes} />
    </div>
  );
};
