import {ErrorMessage, useFormikContext} from 'formik';
import React, {useCallback} from 'react';
import debounce from 'lodash.debounce';
import {getFormikFieldError, getFormikFieldValue, isFormikFieldTouched} from './get-formik-field-value';
import {isDefined} from '@framework/core';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {FormikControlProps} from './formik-control-props';

type FormikDatepickerProps = {
  id?: string,
  label?: string,
  valueFormat?: string,
  onChange?: (evt, val) => void,
} & FormikControlProps;

export function FormikDatePicker(props: FormikDatepickerProps) {
  const formik = useFormikContext();

  const {valueFormat, ...otherProps} = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTextInputChangeDebounce = useCallback(
    debounce((func) => func(), 700),
    [],
  );

  const textFieldProps: any = {
    onBlur: formik.handleBlur,
    // put props at the end so we can overwrite
    // other properties from the component props
    ...otherProps,
  };

  textFieldProps.onChange = (date) => {
    const dateStr = (date? moment(date).format(valueFormat): null);

    const newEvent:any = {};
    newEvent.target = {
      name: props.name,
      value: dateStr,
    };

    formik.handleChange(newEvent);

    if (props.onChange) {
      onTextInputChangeDebounce(() => {
        if (props.onChange){
          props.onChange(date, dateStr);
        }
      });
    }
  };

  const formikValue = getFormikFieldValue(formik, props.name);
  const error = isFormikFieldTouched(formik, props.name) && Boolean(getFormikFieldError(formik, props.name));
  // const helperText = isFormikFieldTouched(formik, props.name) && getFormikFieldError(formik, props.name);
  textFieldProps.value = isDefined(formikValue) ? formikValue : '';

  if (error) {
    textFieldProps.className = `${textFieldProps.className} is-invalid`;
  }

  const date = formikValue? moment(formikValue, valueFormat).toDate(): null;

  return (
    <>
      <div className='input-group mb-2 mr-sm-2'>
        <div className='form-control' style={{padding: 0}}>
          <DatePicker
            {...textFieldProps}
            selected={date}
            className={`form-control ${props.className}`}
            onChange={textFieldProps.onChange}>
          </DatePicker>
        </div>
        <div className='input-group-append'>
          <span className='input-group-text fs-xl'>
            <i className='fa fa-calendar'></i>
          </span>
        </div>
        <ErrorMessage component='div' name={props.name} className='invalid-feedback'/>
      </div>
    </>
  );
}

FormikDatePicker.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  allowNull: PropTypes.bool,
  className: PropTypes.string,
  valueFormat: PropTypes.string,
  dateFormat: PropTypes.string,
};

FormikDatePicker.defaultProps = {
  valueFormat: 'yyyy-MM-DD',
  dateFormat: 'yyyy-MM-dd',
};
