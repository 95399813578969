import React from 'react';
import {RouteWithSubRoutes} from '@framework/react';
import SystemRoutes from './system.routes';

export const SystemModule = (props) => {
  return (
    <div className=''>
      <RouteWithSubRoutes routes={SystemRoutes} />
    </div>
  );
};

SystemModule.propTypes = {
};
