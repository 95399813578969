import moment from 'moment/moment';
import './sessions-list-table.scss';
import {DeleteIconButton} from '@apps/core/src/components/delete-icon-button';
import {EditIconButton} from '@apps/core/src/components/edit-icon-button';
import {SessionUtils} from '../utils/session-utils';
import {useDispatch} from 'react-redux';
import {ClassSessionFormDialog} from './class-session-form-dialog';
import {AlertDialog, ConfirmationDialog, dismissDialog, EmptyDataRow, showDialog} from '@framework/react';
import {ClassSessionsService} from '@app-admin/core';
import {DuplicateIconButton} from '@apps/core/src/components/duplicate-icon-button';
import {useCallback, useState} from 'react';
import {formatDate} from '@framework/core';
import {useDialogActionListener} from '@framework/react';
import {ClassSession, ClassSessionStatusIndexes} from '@apps/core';
import {Badge} from 'react-bootstrap';
import {Link} from 'react-router-dom';

type SessionsListTableProps = {
  sessions: ClassSession[],
  onSelect: (session: ClassSession) => void,
}

const sessionStatusBadgeClass = {
  '1': 'secondary',
  '2': 'warning',
  '3': 'success',
  '4': 'danger',
};

export function SessionsListTable(props: SessionsListTableProps){
  const {sessions} = props;
  const dispatch = useDispatch();
  const [selectedSession, setSelectedSession] = useState({id: 0});

  const [deleteTrigger] = ClassSessionsService.useDeleteClassSessionMutation();
  const handleEdit = function(session){
    dispatch(showDialog(ClassSessionFormDialog, ClassSessionFormDialog.dialogId, session));
  };

  const handleDelete = function(session: ClassSession){
    if (session.po_id){
      const alertConfig = {
        title: 'PO Not Unlink',
        message: `Please unlink PO before delete the session.`,
      };
      dispatch(showDialog(AlertDialog, 'alert', session, alertConfig));
      return;
    }
    const config = {
      message: `Do you want to delete session #${session.id}, ${session.product?.name} on ${formatDate(session?.start || '')})`,
    };
    dispatch(showDialog(ConfirmationDialog, 'confirm-delete-session', session, config));
  };

  const handleDuplicate = function(session){
    const duplicatedSession = {...session};
    duplicatedSession.status = 1;
    duplicatedSession.comments = null;
    // duplicatedSession.vendor_invoice_number = null;
    duplicatedSession.locked = 0;
    duplicatedSession.date = moment(duplicatedSession.date).add(7, 'days').format('YYYY-MM-DD');
    const config = {isClone: true};
    dispatch(showDialog(ClassSessionFormDialog, ClassSessionFormDialog.dialogId, duplicatedSession, config));
  };

  const handleRowSelect = function(item){
    props.onSelect(item);
    setSelectedSession(item);
  };

  const handleDeleteConfirmed = useCallback((session) => {
    deleteTrigger(session.id)
      .then(() => {
        dispatch(dismissDialog(ConfirmationDialog, 'confirm-delete-session'));
      });
  }, [dispatch, deleteTrigger]);

  const handleSessionSaved = useCallback((session) => {
    dispatch(dismissDialog(ClassSessionFormDialog, ClassSessionFormDialog.dialogId));
  }, [dispatch]);

  useDialogActionListener(ClassSessionFormDialog, ClassSessionFormDialog.dialogId,
    ClassSessionFormDialog.Actions.Saved, handleSessionSaved);
  useDialogActionListener(ConfirmationDialog, 'confirm-delete-session',
    ConfirmationDialog.Actions.Ok, handleDeleteConfirmed );

  // @ts-ignore
  return <div className='class-session-list-table'>
    <div>
      <table className='table table-hover table-session-list table-sm table-responsive-sm'>
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            {/*<th>Category</th>*/}
            <th>Teacher</th>
            <th>Students</th>
            <th>Name</th>
            <th>Status</th>
            <th>PO</th>
            {/*<th>V-Inv.</th>*/}
            <th className='text-right'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((item) => {
            const canEdit = SessionUtils.canEdit(item);
            const canDelete = SessionUtils.canDelete(item);

            const poStatus = SessionUtils.getPOStatusDisplayValue(item);
            const status = (item.status || '').toString();

            return <tr key={item.id} onClick={() => handleRowSelect(item) } className={`${selectedSession.id === item.id? 'selected':''}`}>
              <td>
                <i className={`fa fa-${SessionUtils.isLocked(item)? 'lock':'unlock'} icon-in-btn`}/>
                {item.id}
              </td>
              <td>{moment(item.date).format('DD MMM')} {moment(item.start).format('HH:mm')}</td>
              {/*<td>*/}
              {/*  {item.product.product_key}*/}
              {/*</td>*/}
              <td>
                <Link to={`/admin/sessions/user/${item.hostUser.id}`}>{item.hostUser.name}</Link>
              </td>
              {/*<td className='text-center'>{item.total_students}</td>*/}
              <td>
                {item.total_students === 0 && '-'}
                {
                  item.total_students === 1 && item.students &&
                  <Link to={`/admin/sessions/user/${item.students[0].id}`}>{item.students[0].name}</Link>
                }
                {(item.total_students || 0) > 1 && `${item.total_students} students`}
              </td>
              <td>{item.product?.product_key}</td>
              <td>
                <Badge bg={sessionStatusBadgeClass[status]}>
                  {ClassSessionStatusIndexes[status]}
                </Badge>
              </td>
              <td>
                <span style={{padding: '.5rem'}}>
                  {SessionUtils.getPODisplayNumber(item)}
                </span>
                <Badge bg={poStatus === 'Sent'? 'success': 'secondary'}>
                  {SessionUtils.getPOStatusDisplayValue(item)}
                </Badge>
              </td>
              {/*<td className='text-center'>{item.vendor_invoice_number || '-'}</td>*/}
              <td className='text-right'>
                <EditIconButton data={item} disabled={!canEdit} onEdit={handleEdit}/>
                <DuplicateIconButton data={item} onDuplicate={handleDuplicate}/>
                <DeleteIconButton data={item} disabled={!canDelete} onDelete={handleDelete}/>
                {/*<i className='fa fal fa-ellipsis-v'/>*/}
              </td>
            </tr>;
          })}
          {/* @ts-ignore */}
          <EmptyDataRow when={sessions.length === 0} colSpan={10}>No data</EmptyDataRow>
        </tbody>
      </table>
    </div>
  </div>;
}
