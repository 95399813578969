import {ClassSessionsService} from '@app-admin/core';
import {formatDate, isDefined} from '@framework/core';
import {RtkQueryUtils} from '@framework/rtk-query';
import {LoadingIndicator} from '@apps/core/src/components/loading-indicator';
import './session-change-histories-panel.scss';
import {Card} from 'react-bootstrap';

type SessionChangeHistoriesPanelProps = {
  sessionId: number
}

export function SessionChangeHistoriesPanel(props:SessionChangeHistoriesPanelProps){
  const {sessionId} = props;

  const query = ClassSessionsService.useGetChangeHistoriesQuery(sessionId, {skip: !isDefined(sessionId)});

  const histories = query.data?.histories || [];

  return <Card className='session-change-histories-panel'>
    <Card.Header className='title'>
      Histories
      <i className='pull-right fa fal fa-rotate'></i>
    </Card.Header>
    <Card.Body className='body'>
      <div className='histories'>
        {histories.map((item) => {
          return <div key={item.id} className='history-item'>
            <div className='history-summary'>{item.summary}</div>
            <div className='history-owner'>
              {item.user?.name} @ {formatDate(item.created_at)} GMT
            </div>
          </div>;
        })}
        {histories.length === 0 && <div className='empty-data'>No Histories</div> }
        {RtkQueryUtils.isFetching(query) && <LoadingIndicator/>}
      </div>
    </Card.Body>
  </Card>;
}
