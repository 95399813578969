import './delete-icon-button.scss';

type DeleteIconButtonProps = {
  data: any,
  onDelete: (data: any) => void,
  className?: string,
  disabled?: boolean
}

export function DeleteIconButton(props: DeleteIconButtonProps){
  const {data, onDelete, className, ...otherProps} = props;
  const icon = 'fa-trash';
  const handleDelete = function(){
    if (onDelete){
      onDelete(data);
    }
  };
  return <i {...otherProps} className={`fa fal delete-icon-button ${icon} ${className} ${props.disabled? 'disabled':''}`} onClick={handleDelete}></i>;
}
