
export const ClassSessionStatusIndexes = {
  '1': 'Draft', '2': 'Scheduled', '3': 'Completed', '4': 'Cancelled',
};

export const ClassSessionStatus = {
  Draft: 1,
  Scheduled: 2,
  Completed: 3,
  Cancelled: 4,
};
